// import { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useAppSelector, useAppDispatch } from '../..';
// import SampleImageUpload from '../../assets/Images/sampleImageUpload.svg';
// import { RoleCard } from '../CMS/Role';
// import { postRecord } from '../../Services/backend/apiCalls';
// import { setCompareCourse } from '../../Redux/Reducers/comparecoursesReducers';
// import toast from 'react-hot-toast';
// import { PulseLoader } from 'react-spinners';
// import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

// export const CompareCourse = () => {
//     const { state }: any = useLocation();
//     // console.log(state, 'state');
//     const navigate = useNavigate();
//     const dispatch = useAppDispatch();
//     const [loading, setLoading] = useState(false);
//     const [{ token }, { comparecourse }] = useAppSelector(
//         ({ signin, compare }: any) => [
//             signin['signinValue'],
//             compare['compareCoursesData'],
//         ]
//     );

//     const headers = [
//         'Fee',
//         'Trainer',
//         'Placement',
//         'Certification Guidance',
//         'Mode of Training',
//         'Duration of the Course',
//         'Discount',
//         'Course Content Available',
//         'Inhouse Lab',
//         'Trail Ground',
//     ];

//     const userTypeCondition: any = {
//         TI: {
//             name: 'Institute',
//             color: 'bg-[#D3F9EB]',
//             text: 'text-[#33BD87]',
//         },
//         FL: {
//             name: 'Freelancer',
//             color: 'bg-[#E7EFFF]',
//             text: 'text-[#267DF8]',
//         },
//         ST: {
//             name: 'Student',
//             color: 'bg-[#E7EFFF]',
//             text: 'text-[#2975FD]',
//         },
//         CO: {
//             name: 'Corporate',
//             color: 'bg-[#E0FFF3]',
//             text: 'text-[#33BD87]',
//         },
//     };

//     const evenFields =
//         'h-[70px] bg-[#FAFAFA] text-[16px] font-[400] w-full flex items-center justify-center';
//     const oddFields =
//         'h-[70px] text-[16px] font-[400] w-full flex items-center justify-center';
//     const evenFieldsHead =
//         'h-[70px] bg-[#FAFAFA] text-[18px] font-[500] w-full flex items-center pl-4';
//     const oddFieldsHead =
//         'h-[70px] text-[18px] font-[500] w-full flex items-center pl-4';

//     const comparingCourses = async () => {
//         // dispatch(setCompareCourse({ cardState: false }));
//         setLoading(true);
//         const body = {
//             courseIds: state.courseIds,
//         };
//         const response = await postRecord(body, 'comparecourses', navigate);
//         // console.log(response, "response")
//         if (response.status) {
//             dispatch(setCompareCourse({ cardState: false }));
//             setLoading(false);
//             dispatch(setCompareCourse({ comparecourse: response['data'] }));
//         } else {
//             navigate(-1);
//             toast.error(response.message);
//         }
//     };

//     useEffect(() => {
//         state && comparingCourses();
//     }, [state]);

//     if (loading) {
//         return (
//             <div className="flex w-full min-h-[450px] items-center justify-center">
//                 <PulseLoader color="#0074FC" />
//             </div>
//         );
//     }

//     //     state: {
//     //     courseIds: state.courseIds,
//     //     courseTrainerId: state.courseTrainerId,
//     // },
//     const navigateToCourseView = (id: number, title: string) => {
//         // localStorage.setItem("searchFlag", "Y");
//         navigate(`/course/view/${title}/${id}`);
//     };
//     return (
//         <div className="bg-[#F6F7F8]">
//             <div className="w-10/12 mx-auto">
//                 <div className="w-full mt-10 sticky top-0 z-[1] bg-[#F6F7F8] py-4 flex justify-between items-center">
//                     <div>
//                         <h1 className="text-[25px] font-[500]">
//                             Compare Courses
//                         </h1>
//                         <p className="text-[13px] font-[500] mt-2">
//                             <span
//                                 className="hover:text-[#085BEB] cursor-pointer"
//                                 onClick={() => navigate(-1)}
//                             >
//                                 Search
//                             </span>
//                             {/* <span className="ml-2 mr-2">{'>'}</span>
//                             <span
//                                 className="hover:text-[#085BEB] cursor-pointer"
//                                 onClick={() => navigate(-1)}
//                             >
//                                 Web Development
//                             </span> */}
//                             <span className="ml-2 mr-2">{'>'}</span>
//                             Comparing Courses
//                         </p>
//                     </div>
//                     <button
//                         className="text-[16px] p-2 px-4 bg-[#085BEB] text-white rounded-[8px] cursor-pointer"
//                         onClick={() => navigate(-1)}
//                     >
//                         Back
//                     </button>
//                 </div>
//                 <div className="min-h-[500px] bg-white mt-10 mb-10 flex justify-between">
//                     <div className="flex flex-col justify-end w-4/12">
//                         {headers.map((head: any, index: number) => (
//                             <h1
//                                 key={index}
//                                 className={`${
//                                     index % 2 === 0
//                                         ? evenFieldsHead
//                                         : oddFieldsHead
//                                 }`}
//                             >
//                                 {head}
//                             </h1>
//                         ))}
//                     </div>
//                     <div className="flex items-center justify-end w-full">
//                         {comparecourse &&
//                             comparecourse.map((val: any, index: any) => {
//                                 console.log(val, 'val');
//                                 return (
//                                     <div key={index} className="w-full h-full">
//                                         <div className="space-y-4 p-4 flex flex-col items-start">
//                                             <img
//                                                 src={val['image']}
//                                                 alt="CourseImage"
//                                                 className="w-[292px] h-[250px] object-fill"
//                                             />
//                                             <div className="w-full ml-1 min-h-[120px]">
//                                                 <h1
//                                                     className="text-[18px] xl:text-[25px] font-[500] break-words cursor-pointer shrinkToTwoLines"
//                                                     onClick={() =>
//                                                         navigateToCourseView(
//                                                             val?.id,
//                                                             val?.title
//                                                         )
//                                                     }
//                                                 >
//                                                     {val['title']}
//                                                 </h1>
//                                                 <p
//                                                     className="text-[14px] xl:text-[16px] font-[400] text-[#7C7C7C] cursor-pointer"
//                                                     onClick={() => {
//                                                         // state &&
//                                                         localStorage.setItem(
//                                                             'selectedCourseUserId',
//                                                             JSON.stringify(
//                                                                 val?.profile
//                                                                     ?.user_id
//                                                             )
//                                                         );
//                                                         navigate(
//                                                             `/profile/view/${val?.profile?.name}`
//                                                         );
//                                                     }}
//                                                 >
//                                                     by {val['profile']['name']}
//                                                 </p>
//                                             </div>
//                                         </div>
//                                         <div className="flex flex-col mt-4">
//                                             <p className={`${evenFields}`}>
//                                                 <span className="p-1">INR</span>
//                                                 {Number(
//                                                     val['fee']
//                                                 ).toLocaleString('en-IN')}
//                                             </p>
//                                             <div
//                                                 className={`h-[70px] w-full flex items-center justify-center`}
//                                             >
//                                                 <p
//                                                     className={`p-0.5 px-4 text-[13px] font-[500] rounded-[16px] ${
//                                                         userTypeCondition[
//                                                             val['profile'][
//                                                                 'role'
//                                                             ]
//                                                         ].color
//                                                     }
//                                           ${
//                                               userTypeCondition[
//                                                   val['profile']['role']
//                                               ].text
//                                           }`}
//                                                 >
//                                                     {
//                                                         userTypeCondition[
//                                                             val['profile'][
//                                                                 'role'
//                                                             ]
//                                                         ].name
//                                                     }
//                                                 </p>
//                                             </div>
//                                             <p className={`${evenFields}`}>
//                                                 {val['profile'][
//                                                     'placement_assistance'
//                                                 ]
//                                                     ? 'Yes'
//                                                     : 'No'}
//                                             </p>
//                                             <p className={`${oddFields}`}>
//                                                 {val['profile'][
//                                                     'certificate_assistance'
//                                                 ]
//                                                     ? 'Yes'
//                                                     : 'No'}
//                                             </p>
//                                             <p
//                                                 className={`${evenFields} capitalize`}
//                                             >
//                                                 {
//                                                     val['mode_of_teaching'][
//                                                         'mode'
//                                                     ]
//                                                 }
//                                             </p>
//                                             <p
//                                                 className={`${oddFields} capitalize`}
//                                             >
//                                                 {val['duration']}
//                                             </p>
//                                             <p className={`${evenFields}`}>
//                                                 {val['discount_percentage']}
//                                                 <span className="">
//                                                     {val['discount_percentage']
//                                                         ? '%'
//                                                         : '-'}
//                                                 </span>
//                                             </p>
//                                             <p className={`${oddFields}`}>
//                                                 {val['course_content_available']
//                                                     ? 'Yes'
//                                                     : 'No'}
//                                             </p>
//                                             <p className={`${evenFields}`}>
//                                                 {val['profile'][
//                                                     'hands_on_lab_support'
//                                                 ]
//                                                     ? 'Yes'
//                                                     : 'No'}
//                                             </p>
//                                             <p className={`${oddFields}`}>
//                                                 {val['profile'][
//                                                     'corporate_training'
//                                                 ]
//                                                     ? 'Yes'
//                                                     : 'No'}
//                                             </p>
//                                         </div>
//                                     </div>
//                                 );
//                             })}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../..';
import { postRecord } from '../../Services/backend/apiCalls';
import { setCompareCourse } from '../../Redux/Reducers/comparecoursesReducers';
import toast from 'react-hot-toast';
import { PulseLoader } from 'react-spinners';

export const CompareCourse = () => {
    const { state }: any = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [{ token }, { comparecourse }] = useAppSelector(
        ({ signin, compare }: any) => [
            signin['signinValue'],
            compare['compareCoursesData'],
        ]
    );

    const headers = [
        'Fee',
        'Trainer',
        'Placement',
        'Certification Guidance',
        'Mode of Training',
        'Duration of the Course',
        'Discount',
        'Course Content Available',
        'Inhouse Lab',
        'Trail Ground',
    ];

    const userTypeCondition: any = {
        TI: {
            name: 'Institute',
            color: 'bg-green-100',
            text: 'text-green-600',
        },
        FL: {
            name: 'Freelancer',
            color: 'bg-blue-100',
            text: 'text-blue-600',
        },
        ST: {
            name: 'Student',
            color: 'bg-blue-100',
            text: 'text-blue-600',
        },
        CO: {
            name: 'Corporate',
            color: 'bg-green-100',
            text: 'text-green-600',
        },
    };

    const fieldStyle = (isEven: boolean) =>
        `h-16 text-sm font-normal w-full flex items-center justify-center ${
            isEven ? 'bg-gray-100' : ''
        }`;
    const headerStyle = (isEven: boolean) =>
        `h-16 text-lg font-medium w-full flex items-center pl-4 ${
            isEven ? 'bg-gray-100' : ''
        }`;

    const comparingCourses = async () => {
        setLoading(true);
        const body = {
            courseIds: state.courseIds,
        };
        const response = await postRecord(body, 'comparecourses', navigate);
        console.log(response);

        if (response.status) {
            dispatch(setCompareCourse({ cardState: false }));
            setLoading(false);
            dispatch(setCompareCourse({ comparecourse: response['data'] }));
        } else {
            navigate(-1);
            toast.error(response.message);
        }
    };

    useEffect(() => {
        state && comparingCourses();
    }, [state]);

    if (loading) {
        return (
            <div className="flex w-full min-h-[450px] items-center justify-center">
                <PulseLoader color="#0074FC" />
            </div>
        );
    }

    const navigateToCourseView = (id: number, title: string) => {
        navigate(`/course/view/${title}/${id}`);
    };

    return (
        <div className="bg-gray-100">
            <div className="w-10/12 mx-auto">
                <div className="w-full mt-10 sticky top-0 z-[1] bg-gray-100 py-4 flex flex-col md:flex-row justify-between items-center">
                    <div className="text-center md:text-left">
                        <h1 className="text-xl md:text-2xl font-medium">
                            Compare Courses
                        </h1>
                        <p className="text-sm md:text-base font-medium mt-2">
                            <span
                                className="hover:text-blue-600 cursor-pointer"
                                onClick={() => navigate(-1)}
                            >
                                Search
                            </span>
                            <span className="ml-2 mr-2">{'>'}</span>
                            Comparing Courses
                        </p>
                    </div>
                    <button
                        className="text-sm md:text-base p-2 px-4 bg-blue-600 text-white rounded-md cursor-pointer mt-2 md:mt-0"
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </button>
                </div>
                <div className="min-h-[500px] bg-white mt-10 mb-10 flex flex-col md:flex-row justify-between compare-courses">
                    {comparecourse &&
                        comparecourse.map((val: any, index: number) => (
                            <div
                                key={index}
                                className="w-full md:w-1/4 h-full p-4"
                            >
                                <div className="space-y-4 p-4 flex flex-col items-center bg-white shadow-md rounded-md">
                                    <img
                                        src={val['image']}
                                        alt="CourseImage"
                                        className="w-72 h-64 object-cover"
                                    />
                                    <div className="w-full text-center">
                                        <h1
                                            className="text-lg md:text-xl font-medium break-words cursor-pointer"
                                            onClick={() =>
                                                navigateToCourseView(
                                                    val?.id,
                                                    val?.title
                                                )
                                            }
                                        >
                                            {val['title']}
                                        </h1>
                                        <p
                                            className="text-sm md:text-base font-normal text-gray-600 cursor-pointer"
                                            onClick={() => {
                                                localStorage.setItem(
                                                    'selectedCourseUserId',
                                                    JSON.stringify(
                                                        val?.profile?.user_id
                                                    )
                                                );
                                                navigate(
                                                    `/profile/view/${val?.profile?.name}`
                                                );
                                            }}
                                        >
                                            by {val['profile']['name']}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="border-b py-2">
                                            <strong>Fee:</strong> INR{' '}
                                            {Number(val['fee']).toLocaleString(
                                                'en-IN'
                                            )}
                                        </p>
                                        <p className="border-b py-2">
                                            <strong>Trainer:</strong>{' '}
                                            <span
                                                className={`p-1 px-4 text-sm font-medium rounded-lg ${
                                                    userTypeCondition[
                                                        val['profile']['role']
                                                    ].color
                                                } ${
                                                    userTypeCondition[
                                                        val['profile']['role']
                                                    ].text
                                                }`}
                                            >
                                                {
                                                    userTypeCondition[
                                                        val['profile']['role']
                                                    ].name
                                                }
                                            </span>
                                        </p>
                                        <p className="border-b py-2">
                                            <strong>Placement:</strong>{' '}
                                            {val['profile'][
                                                'placement_assistance'
                                            ]
                                                ? 'Yes'
                                                : 'No'}
                                        </p>
                                        <p className="border-b py-2">
                                            <strong>Certification:</strong>{' '}
                                            {val['profile'][
                                                'certificate_assistance'
                                            ]
                                                ? 'Yes'
                                                : 'No'}
                                        </p>
                                        <p className="border-b py-2">
                                            <strong>Mode:</strong>{' '}
                                            {val['mode_of_teaching']['mode']}
                                        </p>
                                        <p className="border-b py-2">
                                            <strong>Duration:</strong>{' '}
                                            {val['duration']}
                                        </p>
                                        <p className="border-b py-2">
                                            <strong>Discount:</strong>{' '}
                                            {val['discount_percentage']
                                                ? `${val['discount_percentage']}%`
                                                : '-'}
                                        </p>
                                        <p className="border-b py-2">
                                            <strong>Course Content:</strong>{' '}
                                            {val['course_content_available']
                                                ? 'Yes'
                                                : 'No'}
                                        </p>
                                        <p className="border-b py-2">
                                            <strong>Lab Support:</strong>{' '}
                                            {val['profile'][
                                                'hands_on_lab_support'
                                            ]
                                                ? 'Yes'
                                                : 'No'}
                                        </p>
                                        <p className="py-2">
                                            <strong>Corporate Training:</strong>{' '}
                                            {val['profile'][
                                                'corporate_training'
                                            ]
                                                ? 'Yes'
                                                : 'No'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
